import axios from '@/libs/api.request'

export function fetchList(query) {
    return axios.request({
        url: '/admin/user/page',
        method: 'get',
        params: query
    })
}

export function addObj(obj) {
    return axios.request({
        url: '/admin/user',
        method: 'post',
        data: obj
    })
}

export function getObj(id) {
    console.log(id)
    return axios.request({
        url: '/admin/user/byId/' + id,
        method: 'get'
    })
}

export function delObj(id) {
    return axios.request({
        url: '/admin/user/' + id,
        method: 'delete'
    })
}

export function putObj(obj) {
    return axios.request({
        url: '/admin/user',
        method: 'put',
        data: obj
    })
}

export const getDetails = (obj) => {
    return axios.request({
        url: '/admin/user/details/' + obj,
        method: 'get'
    })
}

export const searchUserByName = (name) => {
    return axios.request({
        url: '/admin/user/byName/'+name,
    })
}

export const searchUserById = (id) => {
    return axios.request({
        url: '/admin/user/byId/'+id,
    })
}


export function adminPage(query) {
    console.log(query)
    return axios.request({
        url: '/admin/user/adminPage',
        method: 'get',
        params: query
    })
}


export const addDeptAdmin = (deptId,userId,operAuthority) => {
    return axios.request({
        url: '/admin/user/addDeptAdmin?deptId='+deptId+"&userId="+userId+"&operAuthority="+operAuthority,
    })
}
export const deleteDeptAdmin = (deptId,userId) => {
    return axios.request({
        url: '/admin/user/deleteDeptAdmin?deptId='+deptId+"&userId="+userId,
    })
}
