<template>
  <div>
    <Input :value="value" disabled style="width: 50px" />
    <Select
      :ref="ref"
      v-model="model"
      :loading="loading"
      filterable
      :clearable="clearable"
      remote
      :remote-method="remoteMethod"
      @on-change="selectOption"
      @on-clear="clearSelect"
      style="width: 400px"
    >
      <Option
        :value="option.userId"
        :label="showUser(option)"
        v-for="(option, index) in options"
        :key="index"
      >
        <span>{{ option.username }}</span>
        <span style="float: right; color: #ccc">{{ option.realName }}</span>
      </Option>
    </Select>
  </div>
</template>

<script>
import { searchUserByName, searchUserById } from "_api/admin/user";
export default {
  name: "user",
  props: {
    // 基础类型检测 (`null` 指允许任何类型)
    value: {
      type: Number,
      default: function () {
        return -1;
      },
    },
  },
  data() {
    return {
      ref: this.$randomString(),
      model: "",
      clearable: true,
      loading: false,
      options: [],
      result: {},
    };
  },
  computed: {
    showUser() {
      return (option) => {
        return option.username + option.realName;
      };
    },
  },
  methods: {
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        searchUserByName(query).then(({ data }) => {
          this.loading = false;
          if (data.code == 0) {
            this.options = data.data;
            console.log(this.options);
            this.$forceUpdate();
          } else this.options = [];
        });
      } else this.options = [];
    },
    initSelect(v) {
      return new Promise((resolve, reject) => {
        searchUserById(v).then(({ data }) => {
          // console.log(data)
          if (data.code === 0 && data.data !== null) {
            resolve(data.data.username);
          } else resolve("未检索到用户或者用户未添加姓名，用户ID为" + v);
        });
      });
    },
    selectOption(option) {
      this.$emit("input", option);
      this.$emit(
        "valueChange",
        this.options.filter((e) => e.userId == option)
      );
    },
    clearSelect() {
      this.$emit("input", "");
    },
  },
  watch: {
    value: {
      handler(newV, oldV) {
        if (newV !== -1) {
          if (newV == "") return false;
          this.initSelect(newV).then((title) => {
            this.$nextTick(() => {
              // 初始值
              this.$refs[this.ref].$data.query = title;
            });
          });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
</style>
