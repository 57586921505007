<template>
  <div>
    <div class="demo-upload-list" v-for="item in uploadList">
      <template v-if="item.status === 'finished'">
        <img :src="item.url" />
        <div class="demo-upload-list-cover">
          <Icon
            type="ios-eye-outline"
            @click.native="handleView(item.url)"
          ></Icon>
          <Icon
            type="ios-trash-outline"
            @click.native="handleRemove(item)"
          ></Icon>
        </div>
      </template>
      <template v-else>
        <Progress
          v-if="item.showProgress"
          :percent="item.percentage"
          hide-info
        ></Progress>
      </template>
    </div>
    <Upload
      :ref="ref"
      :show-upload-list="false"
      :default-file-list="defaultList"
      :format="['jpg', 'jpeg', 'png']"
      :max-size="2048"
      :on-success="handleSuccess"
      :on-format-error="handleFormatError"
      :on-exceeded-size="handleMaxSize"
      :before-upload="handleBeforeUpload"
      multiple
      type="drag"
      :action="uploadPath"
      style="display: inline-block; width: 100px"
    >
      <div style="width: 100px; height: 100px; line-height: 100px">
        <Icon :type="Icon" size="30"></Icon>
      </div>
    </Upload>
    <Modal title="View Image" v-model="visible">
      <img :src="itemUrl" v-if="visible" style="width: 100%" />
    </Modal>
  </div>
</template>

<script>
import { getUploadPath, upload } from "@/api/upload/uploadv2";
import { validatenull } from "@/libs/validate";

export default {
  name: "photo",
  props: {
    // 基础类型检测 (`null` 指允许任何类型)
    value: {
      type: null,
      default: function () {
        return null;
      },
    },
    Icon: {
      type: String,
      default: function () {
        return "md-camera";
      },
    },
  },
  data() {
    return {
      id: null,
      ref: null,
      uploadPath: getUploadPath,
      defaultList: [],
      itemUrl: "",
      visible: false,
      uploadList: [],
    };
  },
  methods: {
    handleView(url) {
      this.itemUrl = url;
      this.visible = true;
    },
    handleRemove(file) {
      const fileList = this.$refs[this.ref].fileList;
      fileList.splice(
        fileList.findIndex((item) => item.url === file.url),
        1
      );
      this.uploadList = fileList;
    },
    handleSuccess(res, file, fileList) {
      file.url = res.data.url;
      this.uploadList = fileList;
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "图片格式不正确",
        desc:
          "图片 " + file.name + " 格式不对, 请上传jpg, jpeg, png 类型的图片.",
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "提示",
        desc: "图片  " + file.name + " 太大了, 最大支持 2M.",
      });
    },
    handleBeforeUpload(file) {
      const check = this.uploadList.length < 5;
      if (!check) {
        this.$Notice.warning({
          title: "最多存在5张图片.",
        });
      }

      let formData = new FormData();
      formData.append("file", file);

      let that = this;
      //自定义上传方法
      upload(formData).then((res) => {
        debugger;
        let url = res.data.data.url;
        let logId = res.data.data.logId;
        //校验图片是否上传成功
        if (!validatenull(url)) {
          //upload插件添加上传后的内容
          that.$refs[that.ref].fileList.push({
            percentage: 100,
            status: "finished",
            uid: new Date().getTime(),
            logId: logId,
            url: url,
          });
          //通知upload上传成功
          that.$refs[that.ref].onSuccess(
            res.data,
            file,
            that.$refs[that.ref].fileList
          );
        }
      });

      return false;
    },
  },
  mounted() {
    this.uploadList = this.$refs[this.ref].fileList;
  },
  created() {
    this.id = "upload" + this.$randomString();
    this.ref = "upload" + this.$randomString();
  },
  watch: {
    uploadList: function (val) {
      let arr = val.map((item) => {
        return item.url;
      });
      this.$emit("input", arr);
    },
    value: {
      handler(newV, oldV) {
        if (newV) {
          let arrList = newV.map((item) => {
            console.log("9999-==", item);
            //抽取图片名字，防止iview类型判断报错
            let index = item.lastIndexOf("/");
            let name = item.substring(index + 1, item.length);
            return Object.assign(
              {},
              {
                name: name,
                //'logId': logId,
                url: item,
              }
            );
          });
          console.log(arrList);
          //添加已存在图片
          this.defaultList = arrList;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
.demo-upload-list {
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
