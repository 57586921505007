import axios from '@/libs/api.request'
import qs from 'qs'

/**
 * 上传文件
 * @param data
 * @returns {*}
 */
export const upload = data => {
    return axios.request({
        url: 'https://byjs.bjmu.edu.cn/api/material/file/upload',
        data: data,
        method: 'post',
    })
}

export const getUploadPath = '/api/material/file/upload';
