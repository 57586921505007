<template>
    <DatePicker type="datetime" :value="dateTime" @on-change="beginDatePicker"
                :format="format" placeholder="请选择时间" style="width: 220px"></DatePicker>
</template>

<script>
    export default {
        name: "i-date",
        props: {
            value: {

            },
            format:{
                default: 'yyyy-MM-dd HH:mm:ss'
            }
        },
        data() {
            return {
                dateTime: ''
            }
        },
        methods: {
            beginDatePicker(d){
                this.$emit('input', new Date(d).getTime())
            }
        },
        created() {

        },
        computed: {},
        watch: {
            value: {
                handler(v) {
                    this.dateTime = this.$utils.formatDate(v);
                },
                // immediate: true
            },
            dateTime:{
                handler(v){
                    this.$emit('input', new Date(v).getTime())
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>