import Main from "@/components/main";

/**
 * iview-admin中meta除了原生参数外可配置的参数:
 * meta: {
 *  title: { String|Number|Function }
 *         显示在侧边栏、面包屑和标签栏的文字
 *         使用'{{ 多语言字段 }}'形式结合多语言使用，例子看多语言的路由配置;
 *         可以传入一个回调函数，参数是当前路由对象，例子看动态路由和带参路由
 *  hideInBread: (false) 设为true后此级路由将不会出现在面包屑中，示例看QQ群路由配置
 *  hideInMenu: (false) 设为true后在左侧菜单不会显示该页面选项
 *  notCache: (false) 设为true后页面在切换标签后不会缓存，如果需要缓存，无需设置这个字段，而且需要设置页面组件name属性和路由配置的name一致
 *  access: (null) 可访问该页面的权限数组，当前路由设置的权限会影响子路由
 *  icon: (-) 该页面在左侧菜单、面包屑和标签导航处显示的图标，如果是自定义图标，需要在图标名称前加下划线'_'
 *  beforeCloseName: (-) 设置该字段，则在关闭当前tab页时会去'@/router/before-close.js'里寻找该字段名对应的方法，作为关闭前的钩子函数
 * }
 */

export default [
  {
    path: "",
    redirect: "/myspaces",
  },
  {
    path: "/",
    component: Main,
    meta: {
      hideInMenu: true,
      notCache: true,
    },
    children: [
      {
        path: "/myspaces",
        name: "我的地盘",
        meta: {
          hideInMenu: true,
          title: "首页",
          notCache: true,
          icon: "md-home",
        },
        component: (resolve) =>
          require([
            /* webpackChunkName: "group-home" */ "@/views/page/home/home.vue",
          ], resolve),
      },
      {
        path: "/editPass",
        name: "editPass",
        meta: {
          hideInMenu: true,
          notCache: true,
        },
        component: (resolve) =>
          require([
            /* webpackChunkName: "group-home" */ "@/views/page/user/editPass.vue",
          ], resolve),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: {
      hideInMenu: true,
      notCache: true,
    },
    component: (resolve) =>
      require([
        /* webpackChunkName: "group-home" */ "@/views/page/login/login.vue",
      ], resolve),
  },
  {
    path: "/crm/repair/generate",
    name: "generate",
    meta: {
      hideInMenu: true,
      notCache: true,
    },
    component: (resolve) =>
      require([
        /* webpackChunkName: "group-home" */ "@/views/crm/repair/generate/index.vue",
      ], resolve),
  },
  {
    path: "/crm/repair/repair-feedback",
    name: "repair-feedback",
    meta: {
      hideInMenu: true,
      notCache: true,
    },
    component: (resolve) =>
      require([
        /* webpackChunkName: "group-home" */ "@/views/crm/repair/repair-feedback/index.vue",
      ], resolve),
  },
  

  {
    path: "/classroom/courses",
    meta: {
      hideInMenu: true,
      notCache: true,
    },
    component: (resolve) =>
      require([
        /* webpackChunkName: "group-home" */ "@/views/crm/classroom/course-timetable-query/index.vue",
      ], resolve),
  },
  {
    path: "/classroom/use",
    meta: {
      hideInMenu: true,
      notCache: true,
    },
    component: (resolve) =>
      require([
        /* webpackChunkName: "group-home" */ "@/views/crm/classroom/class-use/index.vue",
      ], resolve),
  },
  {
    path: "/classroom/query",
    meta: {
      hideInMenu: true,
      notCache: true,
    },
    component: (resolve) =>
      require([
        /* webpackChunkName: "group-home" */ "@/views/crm/classroom/course-query/index.vue",
      ], resolve),
  },
  {
    path: "/classroom/apply",
    meta: {
      hideInMenu: true,
      notCache: true,
    },
    component: (resolve) =>
      require([
        /* webpackChunkName: "group-home" */ "@/views/crm/classroom/class-apply/index.vue",
      ], resolve),
  },
  {
    path: "/meeting/bespeak",
    meta: {
      hideInMenu: true,
      notCache: true,
    },
    component: (resolve) =>
      require([
        /* webpackChunkName: "group-home" */ "@/views/crm/meeting/meeting-bespeak/index.vue",
      ], resolve),
  },

  {
    path: "/cc",
    name: "cc",
    meta: {
      hideInMenu: true,
      notCache: true,
    },
    component: (resolve) =>
      require([
        /* webpackChunkName: "group-home" */ "@/views/page/table/index.vue",
      ], resolve),
  },
  {
    path: "*",
    name: "error-404",
    meta: {
      title: "404-页面不存在",
    },
    component: () => import("@/views/error-page/404.vue"),
  },
];
