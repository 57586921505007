export default {
    /**
     * @description 配置显示在浏览器标签的title
     */
    title: '教室运维管理系统',
    /**
     * @description token在Cookie中存储的天数，默认1天
     */

    //token有效期，默认为1，具体以当前用户登录选择为准
    cookieExpires: 1,
    /**
     * @description 是否使用国际化，默认为false
     *              如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
     *              用来在菜单中显示文字
     */
    useI18n: false,
    /**
     * @description api请求基础路径
     */
    baseUrl: {
        dev: '/',
        pro: window.apiUrl || '/'
    },
    /**
     * @description 默认打开的首页的路由name值，默认为home
     */
    homeName: 'myspaces',
    /**
     * @description 需要加载的插件
     */
    plugin: {
        'error-store': {
            showInHeader: true, // 设为false后不会在顶部显示错误日志徽标
            developmentOff: true // 设为true后在开发环境不会收集错误信息，方便开发中排查错误
        }
    },
    version: '1.0.0',
    // 配置菜单的属性
    menu: {
        props: {
            label: 'label',
            path: 'path',
            icon: 'icon',
            children: 'children'
        }
    }
}
