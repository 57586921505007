<template>
    <div style="width: 566px">
        <Select
                ref="select"
                v-model="model"
                :loading="loading"
                filterable
                :multiple="multiple"
                remote
                :remote-method="remoteMethod"
                @on-change="selectOption"
                style="width:400px">
            <Option :value="option.userId" :label="showUser(option)" v-for="(option, index) in options" :key="index">
                <span>{{option.username}}</span>
                <span style="float:right;color:#ccc">{{option.realName}}</span>
            </Option>
        </Select>
    </div>
</template>

<script>
    import {searchUserByName, searchUserById} from '_api/admin/user';

    export default {
        name: "user",
        props: {
            // 基础类型检测 (`null` 指允许任何类型)
            value: {
                type: Array,
                default: function () {
                    return []
                }
            },
        },
        data() {
            return {
                model: '',
                multiple: true,
                loading: false,
                options: []
            }
        },
        computed: {
            showUser(){
                return (option) => {
                    console.log(option)
                    return option.username
                }
            }
        },
        methods: {
            remoteMethod(query) {
                if (query !== '') {
                    this.loading = true;
                    searchUserByName(query).then(({data})=> {
                        console.log(data)
                        this.loading = false;
                        if (data.code === 0) {
                            this.options = data.data.records;
                        } else this.options = []
                    })

                } else {
                    this.options = [];
                }
            },
            multipleAjax(arrs) {
                console.log(arrs)
                return new Promise((resolve, reject) => {
                    const arr = arrs.map(item => {
                        return searchUserById(item)
                    });

                    Promise.all(arr).then(res => {
                        const resArr = res.map(res => {
                            let user = res.data.data;
                            return {
                                userId: user.userId,
                                username: user.username,
                                realName: user.realName
                            }
                        })
                        resolve(resArr)
                    })
                })
            },
            selectOption(option) {
                console.log('选中的值')
                this.$emit('input', option)
            }
        },
        watch: {
            value: {
                handler(nowData) {
                    let that = this

                    console.log(nowData.length)

                    if (nowData.length > 0) {
                        that.multipleAjax(nowData).then(list => {
                            console.log('选中的值')
                            console.log(list)
                            that.options = list;
                            that.model = nowData;
                        })
                    }
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
