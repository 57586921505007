<template>
    <header class="header-container">
        <div class="header">
            <div class="header-main">
                <div class="logo">
                    <div>{{website.indexTitle}}</div>
                </div>
                <nav class="full-nav">
                    <div
                            v-for="(item, index) in menuList"
                            @click="clickParentMenu(item,index)"
                            :key="index"
                            :class="{'full-nav-cell-active': item.path === parentActivePath}"
                            class="full-nav-cell">{{item.name}}
                    </div>
                </nav>
                <div class="nav-search">
                    <div class="user-menu shadows">
                        <Dropdown trigger="click" @on-click="clickMenu">
                            <a href="javascript:void(0)" style="color: #ffffff; font-weight: bold">
                                {{userInfo.username}}
                                <Icon type="ios-arrow-down"></Icon>
                            </a>
                            <DropdownMenu slot="list">
                                <DropdownItem divided name="editPass">修改密码</DropdownItem>
                                <!--<DropdownItem>驴打滚</DropdownItem>-->
                                <!--<Dropdown placement="right-start">-->
                                <!--<DropdownItem>-->
                                <!--北京烤鸭-->
                                <!--<Icon type="ios-arrow-forward"></Icon>-->
                                <!--</DropdownItem>-->
                                <!--<DropdownMenu slot="list">-->
                                <!--<DropdownItem>挂炉烤鸭</DropdownItem>-->
                                <!--<DropdownItem>焖炉烤鸭</DropdownItem>-->
                                <!--</DropdownMenu>-->
                                <!--</Dropdown>-->
                                <DropdownItem divided name="logout">
                                    <div>退出登录</div>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div class="header-sub" v-if="subMenu !== undefined && Object.keys(subMenu).length !== 0">
                <div class="header-sub-breadcrumb">
                    <Breadcrumb separator="<b class='demo-breadcrumb-separator'>=></b>">
                        <BreadcrumbItem :to="route.path" v-for="(route, index) in getBreadCrumb" :key="index">{{route.name}}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div class="sub-nav">
                    <template v-for="(item, index) in subMenu">
                        <div
                                @click="clickSubMenu(index,item.id)"
                                :class="{'sub-nav-cell-active': subActivePath === item.path}"
                                class="sub-nav-cell">{{item.name}}
                        </div>
                        <div class="divider" v-show="index !== subMenu.length - 1"></div>
                    </template>

                    <!--<div class="divider"></div>-->
                    <!--<div class="divider"></div>-->
                    <!--<div class="sub-nav-cell">菜单</div>-->
                    <!--<div class="sub-nav-cell">菜单</div>-->
                    <!--<div class="divider"></div>-->
                    <!--<div class="sub-nav-cell">菜单</div>-->
                    <!--<div class="sub-nav-cell">菜单</div>-->
                    <!--<div class="sub-nav-cell">菜单</div>-->
                    <!--<div class="divider"></div>-->
                    <!--<div class="sub-nav-cell">菜单</div>-->
                    <!--<div class="sub-nav-cell">菜单</div>-->
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex'

    export default {
        inject: ["Index", "reload"],
        components: {},
        data() {
            return {
                loginType: 0,
                mheight: 100,
                activeId: 0,
                showDownload: false,
                showShop: false,
                showLogin: false,

                menuList: [],
                activeMenuId: null,
                subMenu: [],
                activeSubMenuId: null,

                parentActivePath: '',
                subActivePath: ''
            }
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
        },
        methods: {
            ...mapActions(['loginOut']),
            logout() {
                let that = this;
                that.$store.dispatch('loginOut').then(() => {
                    that.$router.push({name: '/'})
                    that.$router.go()
                })
            },
            //修改密码
            editPass(){
                //let that = this;
                this.$router.push({name: 'editPass'})
            },
            clickMenu(d) {
                if (d === 'logout') {
                    this.logout()
                }else if(d === 'editPass'){
                    console.log('修改密码')
                    this.editPass()
                }
            },
            clickParentMenu(menu, index) {
                this.parentActivePath = menu.path;
                let openPath = '';
                if (this.menuList[index].children.length === 0) {
                     this.$router.push(this.menuList[index].path);
                    this.subMenu = [];
                    this.$emit('on-select', {})
                }else{
                    this.subMenu = this.menuList[index].children;
                    this.clickSubMenu(0,this.subMenu[0].id)
                }
                if(this.menuList[index].path === this.$route.path) this.reload();

                /*
                if (this.menuList[index].children.length === 0) {
                    this.$router.push(this.menuList[index].path);
                    this.subMenu = [];
                    this.$emit('on-select', {})
                } else {
                    this.subMenu = this.menuList[index].children;
                }
                if(this.menuList[index].path === this.$route.path) this.reload();
                */
            },
            clickSubMenu(index, id) {
                this.subActivePath = this.subMenu[index].path;

                if (this.subMenu[index].children.length === 0) this.$router.push(this.subMenu[index].path);
                else {
                    this.$router.push(this.subMenu[index].children[0].path);
                    this.$emit('on-select', {sub: this.subMenu[index], child: this.subMenu[index].children});
                }

                if(this.subMenu[index].children[0].path === this.$route.path) this.reload();
            },
            initSubMenu(arr) {
                let subMenu = [];
                let slideMenu = {};
                for (let i = 0; i < this.menuList.length; i++) {
                    let item = this.menuList[i];
                    for (let j = 0; j < arr.length; j++) {
                        if (item.path === arr[0].path) {
                            subMenu = item.children;
                        }
                    }
                }
                for (let i = 0; i < subMenu.length; i++) {
                    let item = subMenu[i];
                    for (let j = 0; j < arr.length; j++) {
                        if (item.path === arr[1].path) {
                            slideMenu.sub = arr[1];
                            slideMenu.child = item.children;
                        }
                    }
                }

                return {subMenu, slideMenu};
            }
        },
        computed: {
            ...mapGetters(['userInfo', 'menu', 'website']),
            getRouterPath() {
                let arr = this.$router.$myRouter.getNowPathValue(this.$route);
                if (arr[0].path !== '/' && arr[0].path !== '') {
                    this.parentActivePath = arr[0].path;
                    if (arr.length > 1) {
                        if (arr[1]) this.subActivePath = arr[1].path;
                    }
                } else {
                    this.parentActivePath = arr[1].path;
                }

                return arr;
            },
            getBreadCrumb(){
                let arr = this.$router.$myRouter.getNowPathValue(this.$route);
                console.log(arr)
                if (arr[0].path !== '/' && arr[0].path !== '') {
                    arr[0].path = '';
                }
                return arr;
            },
            subPath() {
                return this.$store.state.app.subPath
            },
            nowParentId() {
                return this.$router.$myRouter.getId(this.$route);
            },
            nowSubId() {
                return this.$router.$myRouter.getValue(this.$route)
            },
            showControlStatus() {
                return this.$store.state.app.showControl
            },
            getUserName() {
                return this.$store.state.user.userName
            }
        },
        created() {
            this.Index.openMenu(this.menuId);
        },
        watch: {
            'menu': function (val) {
                this.menuList = val;
                let {subMenu, slideMenu} = this.initSubMenu(this.getRouterPath);
                this.subMenu = subMenu;
                this.$emit('get-slide', slideMenu);
            }
        }
    }
</script>
<style>
    .demo-breadcrumb-separator{
        color: #1183fb;
        font-weight: bold;
        padding: 0 2px;
    }
</style>
<style scoped lang="less">
    .full-active {
        cursor: pointer;
        background: rgba(0, 0, 0, .1);
        position: relative;
        top: -1px;
    }

    .sub-active {
        border-radius: 4px;
        cursor: pointer;
        color: #0c64eb;
        background: rgba(0, 0, 0, .1);
        position: relative;
        top: -1px;
    }

    .header-container {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .header {
            width: 100%;
            min-width: 1200px !important;
            height: auto;
            background: seagreen;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &-main {
                width: 100%;
                height: 50px;
                color: #ffffff;
                background: #1183fb linear-gradient(-90deg, #0a48d1 0, #1183fb 100%);
                display: flex;
                justify-content: center;
                align-items: center;

                .logo {
                    position: absolute;
                    left: 20px;
                    top: 10px;
                    font-size: 20px;
                    font-weight: bold;
                }

                .full-nav {
                    width: auto;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .full-nav-cell {
                        width: auto;
                        height: 100%;
                        color: #ffffff;
                        font-size: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 15px;
                        background: rgba(0, 0, 0, 0);

                        &:hover {
                            .full-active;
                        }
                    }

                    .full-nav-cell-active {
                        .full-active;
                        font-weight: bold;
                    }
                }

                .nav-search {
                    position: absolute;
                    right: 20px;
                    top: 10px;
                    width: auto;
                    height: 24px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .user-menu {

                    }
                }
            }

            &-sub {
                width: 100%;
                height: 50px;
                background: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .sub-nav {
                    width: auto;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .sub-nav-cell {
                        width: auto;
                        height: 100%;
                        color: #3c4353;
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 12px;
                        background: rgba(0, 0, 0, 0);
                        position: relative;

                        &:hover {
                            .sub-active;
                            color: #0c64eb;
                            font-weight: bold;
                        }
                    }

                    .sub-nav-cell-title {
                        color: #000;
                        font-weight: bold;
                        position: relative;
                        top: -1px;

                        &:hover {
                            color: #000;
                            background: #fff;
                            cursor: default;
                        }
                    }

                    .divider {
                        display: block;
                        width: 2px;
                        height: 20px;
                        margin: 9px 5px;
                        background-color: rgba(0, 0, 0, .05);
                    }

                    .sub-nav-cell-active {
                        .sub-active;
                        background: rgba(0, 0, 0, 0);
                        font-weight: bold;
                    }
                }

                &-breadcrumb{
                    position: absolute;
                    left: 2.5%;
                    top: 50%;
                    width: auto;
                    height: 40px;
                    /*background: slategrey;*/
                    margin-top: -20px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
        }
    }
</style>
