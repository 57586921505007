<template>
    <div style="height: 100%" class="main">
        <top @on-select="selectSubMenu" @get-slide="selectSubMenu"></top>
        <div class="main-body">
            <side :menu="slideMenu" v-if="Object.keys(slideMenu).length > 0"></side>
            <Content class="main-content-con">
                <div class="main-layout-con">
                    <Content class="content-wrapper">
                        <router-view v-if="isRouterAlive" />
                    </Content>
                </div>
                <bottom></bottom>
            </Content>
        </div>
    </div>
</template>
<script>
    import top from './components/top'
    import bottom from '../flex/bottom'
    import side from './components/side'
    import './main.less'
    import {mapGetters} from 'vuex'
    import {getStore} from '@/libs/store'
    import {validatenull} from '@/libs/validate'

    export default {
        name: 'Main',
        provide() {
            return {
                Index: this,
                reload: this.reload
            }
        },
        components: {
            top, bottom, side
        },
        data() {
            return {
                isRouterAlive:true,

                collapsed: false,
                isFullscreen: false,
                slideMenu: {}
            }
        },
        computed: mapGetters(['userInfo', 'isLock', 'isCollapse', 'website', 'expires_in']),
        methods: {
            selectSubMenu(menu) {
                this.slideMenu = menu;
            },
            openMenu(item = {}) {
                this.$store.dispatch("GetMenu", {type: true, id: item.id}).then(data => {
                    if (data.length !== 0) {
                        this.$router.$myRouter.formatRoutes(data, true);
                    }
                });
            },
            // 实时检测刷新token
            refreshToken() {
                this.refreshTime = setInterval(() => {
                    const token = getStore({
                        name: 'access_token',
                        debug: true
                    })
                    if (validatenull(token)) {
                        return
                    }
                    if (this.expires_in <= 5000 && !this.refreshLock) {
                        this.refreshLock = true
                        this.$store
                            .dispatch('RefreshToken')
                            .catch(() => {
                                clearInterval(this.refreshTime)
                            })
                        this.refreshLock = false
                    }
                    this.$store.commit('SET_EXPIRES_IN', this.expires_in - 10)
                }, 5000)
            },
            reload (){
                this.isRouterAlive = false;
                this.$nextTick(function(){
                    this.isRouterAlive = true
                })
            }
        },
        created() {
            // 实时检测刷新token
            this.refreshToken()
        },
        watch: {
            '$route'(newRoute) {
                // this.slideMenu = {};
            }
        }
    }
</script>
