<template>
    <footer class="footer">
        <div class="footer-container" style="display:none;">
            <div class="breadcrumb">
                <div></div>
                <Breadcrumb>
                    <BreadcrumbItem :to="route.path" v-for="(route, index) in getRouterPath" :key="index">{{route.name}}</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <div class="powered-by">
                <!--<div><strong><a href="https://cn.vuejs.org/" target="_blank">Vue-</a></strong></div>&nbsp;&nbsp;&nbsp;&nbsp;-->
                <a href="https://cn.vuejs.org/" target="_blank">{{website.indexTitle}}</a> &nbsp;
            </div>
        </div>
    </footer>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'bottom',
        data() {
            return {}
        },
        computed:{
            ...mapGetters(['website']),
            getRouterPath() {
                let arr = this.$router.$myRouter.getNowPathValue(this.$route);
                console.log(arr)
                if (arr[0].path !== '/' && arr[0].path !== '') {
                    arr[0].path = '';
                }
                return arr;
            },
        },
        methods: {

        },
        watch:{

        }
    }
</script>

<style scoped lang="less">
    .footer {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1010;
        /*height: 40px;*/height: 0px;
        background: #fff;
        border-top: 1px solid #eff1f7;
        display: flex;
        justify-content: center;
        align-items: center;

        &-container {
            font-size: 13px;
            color: #3c4353;
            width: 100%;
            padding: 0 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            .breadcrumb {
                width: 100%;
                display: flex;
                justify-content: flex-start;
            }

            .powered-by {
                position: absolute;
                padding-right: 50px;
                right: 0;
                bottom: 0;
                height: 100%;
                width: auto;
                display: flex;
                align-items: center;
            }
        }

    }
</style>
