import axios from '@/libs/api.request'
import qs from 'qs'

export function getMenu(id) {
    return axios.request({
        url: '/admin/menu',
        params: {parentId: id},
        method: 'get'
    })
}

export function getTopMenu() {
    return axios.request({
        url: '/admin/menu',
        params: {type: 'top'},
        method: 'get'
    })
}

export function fetchMenuTree(lazy, parentId) {
    return axios.request({
        url: '/admin/menu/tree',
        method: 'get',
        params: {lazy: lazy, parentId: parentId}
    })
}

export function addObj(obj) {
    return axios.request({
        url: '/admin/menu',
        method: 'post',
        data: obj
    })
}

export function getObj(id) {
    return axios.request({
        url: '/admin/menu/getById/' + id,
        method: 'get'
    })
}

export function delObj(id) {
    return axios.request({
        url: '/admin/menu/' + id,
        method: 'delete'
    })
}

export function putObj(obj) {
    return axios.request({
        url: '/admin/menu',
        method: 'put',
        data: obj
    })
}
