import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routers";
import store from "@/store";
import { setToken, getToken, canTurnTo, setTitle } from "@/libs/util";
import config from "@/config";
import MyRouter from "./my-router";
import { getterToken } from "@/libs/route.utils";

const { homeName } = config;

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

//创建路由
const createRouter = () => {
  return new VueRouter({
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        if (from.meta.keepAlive) {
          from.meta.savedPosition = document.body.scrollTop;
        }
        return {
          x: 0,
          y: to.meta.savedPosition || 0,
        };
      }
    },
    routes,
    mode: "hash",
    //mode: 'history' //隐藏 #
  });
};
const Router = createRouter();
MyRouter.install(Router, store);
Router.$myRouter.formatRoutes(store.state.user.menu, true);

const LOGIN_PAGE_NAME = "login";

Router.beforeEach((to, from, next) => {
  console.log("router-index", to, from, next);
  if (store.getters.access_token) {
    if (to.path === "/login") {
      next({ name: homeName });
    } else if (to.path.startsWith("/classroom")) {
      next();
    } else {
      if (store.getters.roles.length === 0) {
        store
          .dispatch("GetUserInfo")
          .then(() => {
            next();
          })
          .catch(() => {
            store.dispatch("FedLogOut").then(() => {
              next({
                name: LOGIN_PAGE_NAME,
              });
            });
          });
      } else {
        next();
      }
    }
  } else {
    if (
      to.path === "/login" ||
      to.path.startsWith("/classroom/") ||
      to.path.startsWith("/crm/repair/generate") ||
      to.path.startsWith("/crm/repair/repair-feedback")
    ) {
      next();
    } else {
      next({
        name: LOGIN_PAGE_NAME,
      });
    }
  }
});

Router.afterEach((to) => {
  store.commit("saveSubId", to.path);
  setTitle(to, to.name);
  window.scrollTo(0, 0);
});

export default Router;
