import {
    localSave,
    localRead
} from '@/libs/util'
import {saveErrorLogger} from '@/api/data'
import config from '@/config'

const {homeName} = config

export default {
    state: {
        // 展示工作台
        showControl: false,

        homeRoute: {},
        local: localRead('local'),
        errorList: [],
        hasReadErrorPage: false,
        routeName: '',
        nowParentId: -1,
        nowSubId: -1
    },
    getters: {
        getRouteName: state => state.routeName,
        errorCount: state => state.errorList.length
    },
    mutations: {
        // 设置是否展示工作台
        setShowControl(state, status) {
            state.showControl = status
        },

        setLocal(state, lang) {
            localSave('local', lang)
            state.local = lang
        },
        addError(state, error) {
            state.errorList.push(error)
        },
        setHasReadErrorLoggerStatus(state, status = true) {
            state.hasReadErrorPage = status
        },
        saveRoute(state, info) {
            state.routeName = info;
        },
        saveParentId(state, info){
            state.nowParentId = info
        },
        saveSubId(state, info){
            state.nowSubId = info
        }
    },
    actions: {
        addErrorLog({commit, rootState}, info) {
            // if (!window.location.href.includes('error_logger_page')) commit('setHasReadErrorLoggerStatus', false)
            // const { user: { token, userId, userName } } = rootState
            // let data = {
            //   ...info,
            //   time: Date.parse(new Date()),
            //   token,
            //   userId,
            //   userName
            // }
            // saveErrorLogger(info).then(() => {
            //   commit('addError', data)
            // })
        }
    }
}
