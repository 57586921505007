<template>
    <div class="editor-wrapper">
        <div :id="editorId"></div>
    </div>
</template>

<script>
    import Editor from 'wangeditor'
    import {upload} from '../../api/upload/upload'
    export default {
        name: 'Editor',
        props: {
            value: {
                type: String,
                default: ''
            },
            /**
             * 绑定的值的类型, enum: ['html', 'text']
             */
            valueType: {
                type: String,
                default: 'html',
            },
            /**
             * @description 是否开启本地存储
             */
            cache: {
                type: Boolean,
                default: true
            },
            height: {
                type: Number,
                default: 300
            }
        },
        computed: {},
        data() {
            return {
                editorId: 'editor' + this.$randomString()
            }
        },
        methods: {
            setHtml(val) {
                this.editor.txt.html(val)
            }
        },
        created() {
        },
        mounted() {
            console.log(`#${this.editorId}`)
            this.editor = new Editor(`#${this.editorId}`);
            // /* 配置菜单栏 */
            this.editor.config.menus = [
                'head',  // 标题
                'bold',  // 粗体
                'fontSize',  // 字号
                'fontName',  // 字体
                'lineHeight',//行高
                'hhSpacing',//字符间距
                'italic',  // 斜体
                'underline',  // 下划线
                'strikeThrough',  // 删除线
                'foreColor',  // 文字颜色
                'backColor',  // 背景颜色
                // 'link',  // 插入链接
                'list',  // 列表
                'justify',  // 对齐方式
                'quote',  // 引用
                // 'emoticon',  // 表情
                'image',  // 插入图片
                'table',  // 表格
                // 'code',  // 插入代码
                'undo',  // 撤销
                'redo'  // 重复
            ];

            this.editor.config.uploadImgMaxLength = 5
            this.editor.config.uploadImgMaxSize = 3 * 1024 * 1024
            /* 自定义图片上传（支持跨域和非跨域上传，简单操作）*/
            this.editor.config.customUploadImg = async (files, insert) => {
                /* files 是 input 中选中的文件列表 */
                let formData = new FormData()
                formData.append('file', files[0])
                let data = await upload(formData).then(res => {
                    return res.data;
                });
                console.log(data)
                if(data.code == 0) insert(data.data.url);
            }

            //自定义内容区高度
            this.editor.config.height = this.height;

            this.editor.config.onchange = (html) => {
                let text = this.editor.txt.text()
                this.$emit('input', this.valueType === 'html' ? html : text)
                this.$emit('on-change', html, text)
            }
            this.editor.config.onchangeTimeout = 200;
            // create这个方法一定要在所有配置项之后调用
            this.editor.create()
            console.log('到这一步了')
            // 如果本地有存储加载本地存储内容
            let html = this.value || localStorage.editorCache
            if (html) this.editor.txt.html(html)
        }
    }
</script>

<style lang="less">
    /*.editor-wrapper * {*/
        /*z-index: 100 !important;*/
    /*}*/
</style>
