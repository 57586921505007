import {setToken, getToken, encryption, deepClone} from '@/libs/util'
import {login, getUserInfo, refreshToken} from '@/api/user'
import {isURL, validatenull} from '@/libs/validate'
import {getStore, setStore} from '@/libs/store'
import {getMenu, getTopMenu} from '@/api/admin/menu'
import config from '@/config'

function addPath(ele, first) {
    const menu = config.menu
    const propsConfig = menu.props
    const propsDefault = {
        label: propsConfig.label || 'name',
        path: propsConfig.path || 'path',
        icon: propsConfig.icon || 'icon',
        children: propsConfig.children || 'children'
    }
    const icon = ele[propsDefault.icon]
    ele[propsDefault.icon] = validatenull(icon) ? menu.iconDefault : icon
    const isChild = ele[propsDefault.children] && ele[propsDefault.children].length !== 0
    if (!isChild) ele[propsDefault.children] = []
    if (!isChild && first && !isURL(ele[propsDefault.path])) {
        ele[propsDefault.path] = ele[propsDefault.path] + '/index'
    } else {
        ele[propsDefault.children].forEach(child => {
            addPath(child)
        })
    }
}

export default {
    state: {
        userInfo: {},
        language:'cn',//语言
        permissions: {},
        roles: [],
        menu: getStore({
            name: 'menu'
        }) || [],
        menuAll: [],
        expires_in: getStore({
            name: 'expires_in'
        }) || '',
        access_token: getStore({
            name: 'access_token'
        }) || '',
        refresh_token: getStore({
            name: 'refresh_token'
        }) || ''
    },
    mutations: {
        SET_ACCESS_TOKEN: (state, access_token) => {
            state.access_token = access_token
            setStore({
                name: 'access_token',
                content: state.access_token,
                type: 'session'
            })
        },
        SET_EXPIRES_IN: (state, expires_in) => {
            state.expires_in = expires_in
            setStore({
                name: 'expires_in',
                content: state.expires_in,
                type: 'session'
            })
        },
        SET_REFRESH_TOKEN: (state, rfToken) => {
            state.refresh_token = rfToken
            setStore({
                name: 'refresh_token',
                content: state.refresh_token,
                type: 'session'
            })
        },
        SET_USER_INFO: (state, userInfo) => {
            state.userInfo = userInfo
        },
        SET_LANGUAGE: (state, language) => {
            state.language = language
        },
        SET_MENU: (state, params = {}) => {
            let {menu, type} = params;
            if (type !== false) state.menu = menu
            setStore({
                name: 'menu',
                content: menu,
                type: 'session'
            })
        },
        SET_MENU_ALL: (state, menuAll) => {
            state.menuAll = menuAll
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_PERMISSIONS: (state, permissions) => {
            const list = {}
            for (let i = 0; i < permissions.length; i++) {
                list[permissions[i]] = true
            }
            state.permissions = list
        }
    },
    actions: {
        //登录
        handleLogin({commit, rootState}, params) {
            
            const user = encryption({
                data: {
                    code: params.code,
                    password: params.password,
                    randomStr: params.randomStr,
                    redomStr: "",
                    username: params.username
                },
                key: 'pigxpigxpigxpigx',
                param: ['password']
            });
            return new Promise((resolve, reject) => {
                login(user.username, user.password, user.code, user.randomStr).then(res => {
                    const data = res.data;
                    // console.log(data);
                    // console.log(data.code);
                    // console.log(data.data.access_token);
                    // console.log(data.data.refresh_token);
                    // console.log(data.data.expires_in);
                    commit('SET_ACCESS_TOKEN', data.data.access_token);
                    commit('SET_REFRESH_TOKEN', data.data.refresh_token);
                    commit('SET_EXPIRES_IN', data.data.expires_in);
                    resolve()
                }).catch(err => {
                    reject(err)
                })
            })
        },
        GetUserInfo({commit}) {
            return new Promise(async (resolve, reject) => {
                await getUserInfo().then((res) => {
                    const data = res.data.data || {}
                    commit('SET_USER_INFO', data.sysUser)
                    commit('SET_ROLES', data.roles || [])
                    commit('SET_PERMISSIONS', data.permissions || [])
                    resolve(data)
                }).catch(() => {
                    reject()
                })
            })
        },
        // 注销session
        FedLogOut({commit}) {
            return new Promise(resolve => {
                // resetRouter();
                commit('SET_MENU', [])
                commit('SET_PERMISSIONS', [])
                commit('SET_USER_INFO', {})
                commit('SET_ACCESS_TOKEN', '')
                commit('SET_REFRESH_TOKEN', '')
                commit('SET_ROLES', [])
                commit('DEL_ALL_TAG')
                commit('CLEAR_LOCK')
                resolve()
            })
        },
        // 获取系统菜单
        GetMenu({commit}, obj) {
            return new Promise(resolve => {
                getMenu(obj.id).then((res) => {
                    const data = res.data.data
                    const menu = deepClone(data)
                    menu.forEach(ele => {
                        addPath(ele)
                    });
                    let type = obj.type
                    commit('SET_MENU', {type, menu})
                    resolve(menu)
                })
            })
        },
        // 刷新token
        RefreshToken({commit, state}) {
            return new Promise((resolve, reject) => {
                refreshToken(state.refresh_token).then(response => {
                    const data = response.data.data
                    commit('SET_ACCESS_TOKEN', data.access_token)
                    commit('SET_REFRESH_TOKEN', data.refresh_token)
                    commit('SET_EXPIRES_IN', data.expires_in)
                    commit('CLEAR_LOCK')
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 退出登录
        loginOut({state, commit}) {
            // return new Promise((resolve, reject) => {
            //     Cookies.set('uid', '', {expires: 0})
            //     Cookies.set('token', '', {expires: 0})
            //     window.clearVuexAlong();
            //     resolve(true)
            // })
            return new Promise(resolve => {
                commit('SET_MENU', [])
                commit('SET_PERMISSIONS', [])
                commit('SET_USER_INFO', {})
                commit('SET_ACCESS_TOKEN', '')
                commit('SET_REFRESH_TOKEN', '')
                commit('SET_ROLES', [])
                commit('DEL_ALL_TAG')
                resolve()
            })

        },
    }
}
