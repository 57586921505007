// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import Cookies from 'js-cookie'
import config from '@/config'
import md5 from 'js-md5'
import globalFunc from '@/libs/globalFunc'
import moment from 'moment'

import * as echarts from 'echarts'

import Chat from 'vue-beautiful-chat'
Vue.use(Chat)

import {getDict} from './api/dict';
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
Vue.use(MintUI)

/**
 * 全局引入
 */
import ViewUI from 'view-design';
import './registerServiceWorker'
import '@/assets/css/index.less'
import '@/assets/icons/iconfont.css'

Vue.use(ViewUI);


import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

Vue.use(VXETable)

import VCharts from 'v-charts'
Vue.use(VCharts)

/**
 * 组件
 */
import cdDate from '@/components/cd-date'
import cdUploadPic from '@/components/cd-upload-pic'
import cdUploadPicV2 from '@/components/cd-upload-picv2'
import cdEditor from '@/components/cd-editor'
import cdUser from '@/components/cd-user'
import cdUserMultiple from '@/components/cd-user-multiple'



/**
 * @description 注册admin内置插件
 */

/**
 * @description 生产环境关掉提示
 */
Vue.config.productionTip = false
/**
 * @description 全局注册应用配置
 */
Vue.prototype.$config = config;
Vue.prototype.$cookies = Cookies;
Vue.prototype.$md5 = md5;
Vue.prototype.$echarts = echarts

Vue.prototype.$randomString = function randomString(len) {
    len = len || 9;
    const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz';
    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    let maxPos = $chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
};

Vue.filter('formatDate', function (value) {
    debugger
    return moment(value).format('YYYY-MM-DD HH:mm:ss')
})

/**
 * 注册指令
 */

/**
 * 注册公共方法
 */
Vue.prototype.$utils = globalFunc;
Vue.prototype.$Dict = getDict;

/**
 * 注册组件
 */
Vue.component('cdDate', cdDate);
Vue.component('cdUploadPic', cdUploadPic);
Vue.component('cdUploadPicV2', cdUploadPicV2);

Vue.component('cdEditor', cdEditor);
Vue.component('cdUser', cdUser);
Vue.component('cdUserMultiple', cdUserMultiple);


/* eslint-disable no-new */
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
