import store from '@/store'
import {getStore} from '@/libs/store'
import errorCode from './errorCode'
import router from '@/router/index'
import qs from 'qs'
import {serialize} from '@/libs/util'
import {LoadingBar, Notice} from 'view-design'
import moment from 'moment'
import { tansParams, blobValidate } from "@/libs/util";
import { saveAs } from 'file-saver'

class HttpRequest {
    constructor(baseUrl = baseURL) {
        this.baseUrl = baseUrl
        this.queue = {}
    }

    getInsideConfig() {
        return {
            baseURL: this.baseUrl,
            timeout: 60000,
            withCredentials: true,
            headers: {
                'Cache-Control': 'no-cache',
                'TENANT-ID': 1
            }
        }
    }

    destroy(url) {
        delete this.queue[url]
    }

    interceptors(instance, url) {
        
        // 请求拦截
        instance.interceptors.request.use(config => {
            LoadingBar.start();
            const TENANT_ID = getStore({name: 'tenantId'})
            const isToken = (config.headers || {}).isToken === false
            const token = store.getters.access_token

            if (config.url.includes('auth/oauth/token')) {
                config.headers['Authorization'] = 'Basic cGlnOnBpZw=='
            }

            if (token && !isToken) {
                config.headers['Authorization'] = 'Bearer ' + token// token
            }

            if (TENANT_ID) {
                config.headers['TENANT-ID'] = TENANT_ID // 租户ID
            }

            // headers中配置serialize为true开启序列化
            if (config.method === 'post'){
                if (config.headers.serialize){ 
                    config.data = serialize(config.data)
                    delete config.data.serialize
                }else{
                    if (config.data!=null && config.data!=undefined){
                        for (var prop of Object.keys(config.data)) {
                            if (config.data[prop] instanceof Date){
                                config.data[prop] = moment(config.data[prop]).format('YYYY-MM-DD HH:mm:ss')
                            }
                        }
                    }    
                }
            }
            if (config.method == 'put'){
                if (config.data!=null && config.data!=undefined){
                    for (var prop of Object.keys(config.data)) {
                        if (config.data[prop] instanceof Date){
                            config.data[prop] = moment(config.data[prop]).format('YYYY-MM-DD HH:mm:ss')
                        }
                    }
                }    
            }

            if (config.method === 'get') {
                config.paramsSerializer = function (params) {
                    return qs.stringify(params, {arrayFormat: 'repeat',
                    serializeDate: (date) => {
                        //用moment处理日期比较方便，自己写格式化方法也可以
                        return moment(date).format('YYYY-MM-DD HH:mm:ss')
                    }})
                }
            }

            this.queue[url] = true;

           // console.log(config);
            return config
        }, error => {
            return Promise.reject(error)
        });
        // 响应拦截
        instance.interceptors.response.use(res => {
            // console.log(res)
            LoadingBar.finish();
            this.destroy(url)
            const status = Number(res.status) || 200
            const message = res.data.msg || errorCode[status] || errorCode['default']
            if (status === 401 || res.data.code === 100) {
                Notice.error({title: message});
                store.dispatch('FedLogOut').then(() => {
                    router.push({path: '/login'})
                })
                return
            }

            if (status !== 200) {
                Notice.error({title: message});
                return Promise.reject(new Error(message))
            }

            return res
        }, error => {
            LoadingBar.finish();
            console.log(error)
            this.destroy(url)
            return Promise.reject(new Error(error))
        })
    }

    request(options) {
        const instance = axios.create(/*{transformRequest: [function (data, headers) {
            if (data != null && data!=undefined){
                debugger
                for (var prop of Object.keys(data)) {
                    if (data[prop] instanceof Date){
                        data[prop] = moment(data[prop]).format('YYYY-MM-DD HH:mm:ss')
                    }
                }
                return JSON.stringify(data) 
            }

            //return moment(date).format('YYYY-MM-DD HH:mm:ss')
            //const d = qs.stringify(data)
            //return data;
        }]}*/)
        instance.defaults.timeout = 60000
// 返回其他状态吗
        instance.defaults.validateStatus = function (status) {
            return status >= 200 && status <= 500 // 默认的
        }

        options = Object.assign(this.getInsideConfig(), options)
        this.interceptors(instance, options.url)
        return instance(options)
    }
    download(url, query, filename, config) {

        const instance = axios.create()
        instance.defaults.timeout = 60000
// 返回其他状态吗
        instance.defaults.validateStatus = function (status) {
            return status >= 200 && status <= 500 // 默认的
        }

        this.interceptors(instance, url)

        //return instance.post('/crm/crrent/exportExcel',
        return instance.post(url,
        query, {
            transformRequest: [(params) => { return tansParams(params) }],
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            responseType: 'blob',
            ...config
          }
        ).then(async (data) => {
      
          const isLogin = await blobValidate(data);
          if (isLogin) {
            const blob = new Blob([data.data])
            saveAs(blob, filename)
          } else {
            //const resText = await data.text();
            //const rspObj = JSON.parse(resText);
            //const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
            //Message.error(errMsg);
          }
        }).catch((r) => {
          console.error(r)
        })
      
      
      }
      
      
}

export default HttpRequest
