const formatDateString = (date) => {
  //var date = new Date(timestamp)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear()
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
  var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
  var h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours())
  var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes())
  var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
  return Y + '' + M + '' + D + '' + h + m + s
}
const formatStringToDate = (str) =>{
  if (str instanceof Date)
    return str;
  return new Date(str)
}
const formatDate = (timestamp) => {
  return formatDateByDate(new Date(timestamp))// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
}
const formatDateByDate = (date) => {
  if (date instanceof Date){
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' '
    var h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':'
    var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':'
    var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
    return Y + M + D + h + m + s
  }
  return date
}

const formatShortDate = (timestamp) => {
  var date = new Date(timestamp)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
  return Y + M + D
}

const formatAreaDate = (s, e) => {
  return [formatDate(s), formatDate(e)]
}

const formatArrayGetIds = (objs) => {
  if (objs !== undefined) {
    if (objs.length > 0) {
      if (typeof (objs[0]) === 'Object') {
        let arr = []
        for (let i = 0; i < objs.length; i++) {
          arr.push(objs[i].id)
        }
        return arr
      }
    } else {
      return []
    }
  }
}
const intToWeekString = (week) =>{
  let weekString = ["日","一","二","三","四","五","六","日"]
  return weekString[week]
}
//判断变量是否为空  空返回TRUE 否则返回 FALSE
const isEmpty = (value) =>
{
	var a = value!=null;
  var b = typeof(value)!="undefined";
  var c = value!=0; 
  if (value!=null && typeof(value)!="undefined" && value!=0) { 
	  return false;
	} else { 
    return true;
	}
}
export default {
  formatDateByDate: formatDateByDate,
  formatDateString: formatDateString,
  formatDate: formatDate,
  formatStringToDate: formatStringToDate,
  formatShortDate: formatShortDate,
  formatAreaDate: formatAreaDate,
  formatArrayGetIds: formatArrayGetIds,
  intToWeekString: intToWeekString,
  isEmpty: isEmpty
}
