import axios from '@/libs/api.request'
import {signRequest} from '@/libs/util'
import qs from 'qs'

const scope = 'server'

//登录接口
export const login = (username, password, code, randomStr) => {
    
    let grant_type = 'password';
    let dataObj = qs.stringify({'username': username, 'password': password});

    return axios.request({
        url: '/auth/oauth/token',
        headers: {
            isToken: false,
            'TENANT-ID': '1',
            'Authorization': 'Basic cGlnOnBpZw=='
        },
        method: 'post',
        params: {randomStr, code, grant_type},
        data: dataObj
    })
};

//获取验证码
export const getCodeStr = (str) => {
    return axios.request({
        url: 'code?randomStr=' + str,
        method: 'get',
        responseType: 'blob'
    })
}

/**
 * 获取用户信息
 * @returns {*}
 */
export const getUserInfo = () => {
    return axios.request({
        url: 'admin/user/info',
        method: 'get'
    })
}

/**
 * 修改密码
 * @returns {*}
 */
//  export const upUserPass = () => {
//     return axios.request({
//         url: 'admin/user/edit',
//         method: 'put'
//     })
// }
export function upUserPass(obj) {
    return axios.request({
      url: 'admin/user/edit',
      method: 'put',
      data: obj
    })
  }

/**
 * 退出登录
 * @returns {*}
 */
export const logout = () => {
    return axios.request.get('admin/token/logout')
}

/**
 * 刷新token
 * @param refresh_token
 * @returns {*}
 */
export const refreshToken = (refresh_token) => {
    const grant_type = 'refresh_token'
    console.log('refresh_token')
    return axios.request({
        url: '/auth/oauth/token',
        headers: {
            'isToken': false,
            'TENANT-ID': '1',
            'Authorization': 'Basic cGlnOnBpZw=='
        },
        method: 'post',
        params: {refresh_token, grant_type, scope}
    })
}

export const sendMobileMsg=(data) =>{
    let signData = signRequest("BA1FCB132618414C8B2C6DB4C82BF0D2",data);
    return axios.request({
        url: '/admin/mobile/sendSmsCode',
        headers: {
            'isToken': false,
            'TENANT-ID': '1',
            'Authorization': 'Basic cGlnOnBpZw=='
        },
        method: 'post',
        data: signData
    })    
}
