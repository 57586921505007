import axios from "@/libs/api.request";

export const getDict = (url, params) => {
  return axios.request({
    url: url,
    params: params,
    method: "get",
  });
};

export const getDictLabel = (value, dict) => {
  return getDictLabel2(value, dict, "—");
};
export const getDictLabel2 = (value, dict, defValue) => {
  if (Array.isArray(value)) {
    let result = [];
    for (let arrayValue of value) {
      if (dict != null) {
        for (let e of dict) {
          if (e.value == arrayValue) {
            result.push(e.label);
          }
        }
      }
    }
    if (result.length > 0) return result.join(",");
  } else {
    if (dict != null) {
      for (let e of dict) {
        if (e.value == value) return e.label;
      }
    }
  }
  if (defValue) return defValue;
  return "—";
};
